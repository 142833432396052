<template>
<div class="themeblock ">
  <div class="themeflex ">
    <div class="container ">
      <div class="row ">
        <div class="col-xs-12 col-md-6 ">
          <div class="bannerImg ">
            <div class="bannerimg01 ">
              <span><img style="" class="img-responsive" src="./assets/bannerimg01.png" alt="Alt" title=""></span>
              </div>
            <div class="bannerimg02 ">
              <span><img style="" class="img-responsive" src="./assets/bannerimg02.png" alt="Alt" title=""></span>
            </div>
            <div class="bannerimg03 ">
              <span><img style="" class="img-responsive" src="./assets/bannerimg03.png" alt="Alt" title=""></span>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 ">
          <div class="bannercnt ">
            <h2>NFT 4 Fine Art Gallery<br><span class="textbluecolor ">Marketplace</span></h2>
            <div id="message">
              <div class="rotator-wrapper">
    <p>
      <span class="rotator">
        <span>We make the impossible possible</span>
        <span>我们让不可能变为可能</span>
        <span>私たちは不可能を可能にします</span>
        <span>نجعل المستحيل</span>
        <span>Мы делаем невозможное возможным</span>
      </span>
    </p>
  </div>
            </div>
            <Countdown 
            deadline="2022-02-28 00:00:00" 
            mainFlipBackgroundColor="#001b2f"
            secondFlipBackgroundColor='#001b2f'
            mainColor="#ffffff" 
            secondFlipColor="#ffffff"
            labelColor="#606060"
             />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>




<script>
import {Countdown} from 'vue3-flip-countdown'


export default {
  name: 'App',
  components: {
    Countdown
  },
}
</script>




<style>
@import './assets/bootstrap/css/bootstrap.css';

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

@font-face {
    font-family: Metronic Pro;
    src: url('./assets/font/MetronicPro.woff') format('woff');
}

body {
  background: #050D1D;
  color: #5c6873;
  font-family: 'Metronic Pro', sans-serif;
  padding: 0px !important;
  margin: 0px !important;
  font-weight: 300;
  font-size: 17px; 
}

.front {
    background: #050D1D;
    position: relative;
}

.themeblock {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.front .themeflex {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.circlebg1 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

.bannerImg {
    position: relative;
    display: inline-flex;
    height: 360px;
}

.bannerimg01 {
    margin: 0 -85px 0 0;
}

.bannerimg02 {
    margin: 25px -55px 0 0;
}

.bannerimg03 {
    margin: 55px 0 0 -30px;
}

.bannercnt {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    padding: 16px 0 0 0;
    max-width: 400px;
    width: 100%;
    height: 360px;
    flex-direction: column;
    justify-content: center;
}

.bannercnt h2 {
    font-size: 32px;
    line-height: 52px;
    margin: 0 0 40px;
    color: #FFFFFF;
}

.bannercnt p {
    font-size: 20px;
    margin: 0 0 60px;
    line-height: 30px;
    color: #FFFFFF;
}

.textbluecolor {
    color: #019DEA;
}

#animation .new {
    opacity: 0; 
}

#animation { 
    display:none;
    font-size: 20px;
    margin: 0 0 40px;
    line-height: 30px;
    color: #FFFFFF;
}

#animation .div_opacity {
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 1;
}

#message { 
    font-size: 20px;
    margin: 0;
    line-height: 30px;
    color: #FFFFFF;
    width: 100%;
}


.countdownHolder {
    font-size: 28px;
    padding-top: 30px;
}

.countdownHolder {
    width: auto;
    margin: 0 auto;
    font: 28px/1.5 'Open Sans Condensed',sans-serif;
    text-align: center;
    letter-spacing: -3px;
}

#jquery-countdown-timer-note, .countDiv3, .countSeconds {
    display:none;
}

.digit.static {
    box-shadow: 1px 1px 1px rgb(4 4 4 / 35%);
    background-image: linear-gradient(bottom, #001b2f 50%, #001b2f 50%);
    background-image: -o-linear-gradient(bottom, #001b2f 50%, #001b2f 50%);
    background-image: -moz-linear-gradient(bottom, #001b2f 50%, #001b2f 50%);
    background-image: -webkit-linear-gradient(bottom, #001b2f 50%, #001b2f 50%);
    background-image: -ms-linear-gradient(bottom, #001b2f 50%, #001b2f 50%);
    background-image: -webkit-gradient( linear, left bottom, left top, color-stop(0.5, #001b2f), color-stop(0.5, #001b2f) );
}

.countdownHolder {
    width: auto;
    margin: 0 auto;
    font-family: 'Metronic Pro', sans-serif;
    text-align: center;
    letter-spacing: -3px;
}




.rotator-wrapper {
  position: relative;
}
.rotator-wrapper span {
  display: inline-block;
  min-width: 100%;
  text-align: left;
}
.rotator-wrapper span span {
  position: absolute;
  top: -0px;
  opacity: 0;
  animation: rotateWord 18s linear infinite 0s;
  color: #ffffff;
}
.rotator-wrapper span span:nth-child(2) {
  animation-delay: 3s;
}
.rotator-wrapper span span:nth-child(3) {
  animation-delay: 6s;
}
.rotator-wrapper span span:nth-child(4) {
  animation-delay: 9s;
}
.rotator-wrapper span span:nth-child(5) {
  animation-delay: 12s;
}
@keyframes rotateWord {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 0;
    transform: translateY(-30px);
  }
  5% {
    opacity: 1;
    transform: translateY(0px);
  }
  15% {
    opacity: 1;
    transform: translateY(0px);
  }
  20% {
    opacity: 0;
    transform: translateY(30px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.flip-clock[data-v-3d37764e] {
    text-align: center;
    perspective: 600px;
    margin: 0 !important;
    font-family: 'Metronic Pro', sans-serif !important;
    display: flex;
    justify-content: flex-start;
}

@media (max-width: 480px) {
.lng {
    margin-bottom: 40px;
    margin-top: 40px;
    justify-content: center;
}
.bannerImg {
    height: auto;
}
.bannercnt {
    display: flex;
    align-content: center;
    flex-wrap: nowrap;
    padding: 16px 0 0 0;
    width: 100%;
    height: auto;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.bannercnt h2 {
    font-size: 22px;
    line-height: 52px;
    margin: 0;
    color: #FFFFFF;
}
#animation {
    font-size: 16px;
    margin: 0;
}
#message {
    font-size: 16px;
    margin: 0;
}
.bannercnt p {
    font-size: 16px;
    margin: 0 0 60px;
    line-height: 30px;
    color: #FFFFFF;
}
.flip-clock[data-v-3d37764e] {
    justify-content: center;
}
.rotator-wrapper span span {
    text-align: center;
}
}
</style>
